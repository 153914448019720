/*eslint-disable */
// import AdmissionModel from '../../Model/Admission'
import request from '../../Utils/curl'

let Admissions = {
  /**
   * admission_dashboard
   */
  async admission_dashboard (context, whereFilter = null) {
    try{
      let postData = new FormData();
      if (whereFilter) {
        postData.append("filter", JSON.stringify(whereFilter))
      }

      return await request.curl(context, "admission_list", postData)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at admission_dashboard() and Exception:',err.message)
    }
  },

  /**
   * admissionView
   */
  async admissionView (context, admissionId) {
    try {
      
      let post_data = new FormData();
      post_data.append('adm_id', admissionId);
      return await request.curl(context, "admission_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at admissionView() and Exception:",err.message)
    }
    
  },

  /**
   * admissionAdd
   */
  async admissionAdd (context, admissionObj) {
    try{
    let post_data = new FormData();
    
    for (let key in admissionObj) {
      post_data.append(key, admissionObj[key]);
    }

    return await request.curl(context, "admission_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at admissionAdd() and Exception:',err.message)
    }
  },

  /**
   * admissionEdit
   */
  async admissionEdit (context, admissionObj) {
    try{
      let post_data = new FormData();

      for (let key in admissionObj) {
        if (admissionObj[key] && typeof admissionObj[key] != 'number') {
          post_data.append(key, admissionObj[key]);
        }
        else if (typeof admissionObj[key] == 'number'){
          post_data.append(key, admissionObj[key]);
        }
      }

      return await request.curl(context, "admission_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at admissionEdit() and Exception:',err.message)
    }
  },

  /**
   * admissionDelete
   */
  async admissionDelete (context, admissionId) {
    try{
    let post_data = new FormData();
    
    post_data.append('adm_id', admissionId);

    return await request.curl(context, "admission_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at admissionDelete() and Exception:',err.message)
    }
  }
}

export { 
  Admissions
};
