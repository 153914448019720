<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title mt-4">
              <i class="fa fa-solid fa-file-text" aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:body>
            <div class="row w-100 ml-2 mb-3 multiple_boxes_row">
                <div class="boxs" v-for="item in admDashBoardList" :key="item.adm_state">
                  <iq-card class="btn mb-1 multiCardboxs" :style="getAdmissionStateColorCode(item.adm_state)">
                    <span class="textintworow text-white">{{getStateDefinition(item.adm_state)}} </span><br>
                    <span class="badge badge-light ml-2">{{item.totalCount}}</span>
                  </iq-card>
                </div>
            </div>
            <b-row class="row w-100">
              <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col sm="8" md="8" lg="8" xl="8" class="pr-0 mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getadmissionListDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="admissionObjList && admissionObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="admissionObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(student_name)="data">
                    <p v-html="data.item.student_name" class="text-wrap w-100px">
                    </p>
                  </template>
                  <template v-slot:cell(student_email)="data">
                    <p v-html="data.item.student_email" class="text-wrap w-100px">
                    </p>
                  </template>
                  <template v-slot:cell(sch_name)="data">
                    <p v-html="data.item.sch_name" class="text-wrap w-100px">
                    </p>
                  </template>
                  <template v-slot:cell(sch_city)="data">
                    <p v-html="data.item.sch_city" class="text-wrap w-100px">
                    </p>
                  </template>
                  <template v-slot:cell(sch_country)="data">
                    <p v-html="data.item.sch_country" class="text-wrap w-100px">
                    </p>
                  </template>
                  <template v-slot:cell(org_name)="data">
                    <p v-html="data.item.org_name" class="text-wrap w-100px">
                    </p>
                  </template>
                  <template v-slot:cell(cour_degree_name)="data">
                    <p class="text-wrap w-100">{{data.item.cour_degree_name = data.item.cour_degree_name.replace(/\"/g, "")}}
                    </p>
                  </template>
                  <template v-slot:cell(cour_name)="data">
                    <p class="text-wrap w-100">{{data.item.cour_name.replace(/\"/g, "").replace(data.item.cour_degree_name+" - ","")}}
                    </p>
                  </template>
                  <template v-slot:cell(batch)="data">
                    <p class="text-right">{{data.item.batch.replace(/\"/g, "")}}
                    </p>
                  </template>
                  <template v-slot:cell(adm_state)="data">
                    <b-badge class="text-wrap w-125px" :style="getAdmissionStateColorCode(data.item.adm_state)">
                      {{admissionStateInfo[data.item.adm_state]}}
                    </b-badge><br/>

                    <div class="text-wrap w-125px" v-if="data.item.user_id === userData.user_id && data.item.adm_state === 'UNIV_APP_OFFER_SENT'">
                      <b-badge :style="getAdmissionStateColorCode('STU_APP_ACCEPTED')" class="mt-2 pointer" @click="studentChangeApplicationStatus(data.item, 'STU_APP_ACCEPTED')">
                        Accept
                      </b-badge>
                      <b-badge :style="getAdmissionStateColorCode('STU_APP_REJECTED')" class="ml-4 mt-2 pointer" @click="selectedAdmissionItem=data.item; showRejectScreen = true;">
                        Reject
                      </b-badge>
                    </div>
                  </template>
                  <template v-slot:cell(adm_message)="data">
                    <p v-if="!data.item.adm_message || data.item.adm_message == 'null'" style="text-align:left !important">
                      -
                    </p>
                    <p v-else v-html="data.item.adm_message" class="text-wrap fourLineOnly pointer w-200">
                    </p>
                  </template>
                  <template v-slot:cell(created_on)="data">
                    <p class="text-wrap">{{data.item.created_on | dateFormat}}</p>
                  </template>
                  <template v-slot:cell(action)="data">
                    <div class="text-wrap">
                      <b-button :title="getTitle(data.item.adm_state)" v-if="data.item.user_id === userData.user_id" variant="iq-bg-success mr-1 mb-1" size="sm" @click="admissionEdit(data.item)">
                        <i class="fa-solid fa-pen m-0 font-size-20"></i>
                      </b-button>
                      <b-button variant="mb-1" title="View Submited Application" size="sm" @click="admissionView(data.item)" v-if="!data.item.editable && data.item.adm_state != 'STU_ADM_START'">
                        <i class="fa-solid fa-file m-0 font-size-20"></i>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal v-model="showRejectScreen" title="Please provide reasons for your choice">
      <div class="row mb-3 ml-3 w-100">
        <div class="col-4 pl-0 ml-0" >
          <select class="form-control" v-model="admissionObj.adm_reason_code" id="validation_reason">
            <option v-for="(reason, index) of cvStudentRejectionReasonOptions" :key="index+'reason'" :value="reason">
              {{reason}}
            </option>
          </select>
        </div>
        <div class="col-8">
          <textarea
            rows="1"
            v-model="admissionObj.adm_message"
            type="textarea"
            class="form-control"
            id="validation_reason"
            autogrow></textarea>
        </div>
      </div>
      <template #modal-footer="">
        <b-button size="sm" >
          Cancel
        </b-button>
        <b-button variant="primary" size="sm" class="pull-left" @click="studentChangeApplicationStatus(selectedAdmissionItem, 'STU_APP_REJECTED')">
          Submit
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style lang="scss" scoped>
.dashboardCard {
  height: 75px;
  padding: 11px;
}
.fourLineOnly:hover {
  overflow: visible !important;
  -webkit-line-clamp: unset;
}
.dateRange {
  float: right;
}
.multiCardboxs{
  background-color:red !important;
}
</style>
<script>
import { Admissions } from "../../../../FackApi/api/admission.js"
import moment from "moment"
import ApiResponse from "../../../../Utils/apiResponse.js"
import { socialvue } from "../../../../config/pluginInit.js"
import config from "../../../../Utils/config.js"
import { analyticsAdmissionDashboard } from "../../../../FackApi/api/analytics/analytics_admission_dashboard"

export default {
  name: "admission_dashboard",
  components: {
  },
  data () {
    return {
      apiName: "admission_list",
      cvCardTitle: "Admissions",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Admission",
      cvAddModalHeader: "Add Admission",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Admission Dashboard",
      showModelAdmissionAdd: false,
      showModelAdmissionEdit: false,
      showModelAdmissionDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [],
      admissionObjList: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      selectedAdmissionObj: {},
      admissionOpenModal: false,
      dateFormat: "mm/dd/yyyy",
      userProfileUnlockState: config.UserProfileUnlockState,
      admissionStateInfo: config.AdmissionStateInfo,
      cvAdmissionReasonOptions: config.AdmissionNeedInfoReasons,
      cvStudentRejectionReasonOptions: config.StudentRejectionReasons,
      admDashBoardList: [],
      admissionAnalyticsObj: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      },
      admissionObj: {},
      showRejectScreen: false,
      selectedAdmissionItem: {}
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  async mounted () {
    socialvue.index()
    await this.getDateFilter()
    this.cvCardTitle = "Admission Dashboard"
    let self = this

    switch (this.userData.user_role) {
      case "USERROLE11114": // Student
        self.columns = [
          { label: "# ", key: "id", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "Action ", key: "action", class: "text-left align-text-top text-nowrap w-100px", sortable: false },
          { label: "Status", key: "adm_state", class: "text-left align-text-top text-nowrap w-125px", sortable: true },
          { label: "University", key: "org_name", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "Degree / Diploma", key: "cour_degree_name", class: "text-left align-text-top text-nowrap w-200px", sortable: true },
          { label: "Specialization", key: "cour_name", class: "text-left align-text-top text-nowrap w-200px", sortable: true },
          { label: "Admission Cycle", key: "batch", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "Remarks", key: "adm_message", class: "text-left align-text-top text-nowrap w-200px", sortable: true },
          { label: "Applied On ", key: "created_on", class: "text-left align-text-top text-nowrap w-100px", sortable: true }
        ]
        break
      case "USERROLE11115": // Univ
        self.columns = [
          { label: "# ", key: "id", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "Action ", key: "action", class: "text-left align-text-top text-nowrap w-100px", sortable: false },
          { label: "Status", key: "adm_state", class: "text-left align-text-top text-nowrap w-125px", sortable: true },
          { label: "Name", key: "student_name", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "Email", key: "student_email", class: "text-left align-text-top text-nowrap w-260px", sortable: true },
          { label: "Contact No.", key: "student_mobile", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "School", key: "sch_name", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "City", key: "sch_city", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "Country", key: "sch_country", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "Degree / Diploma", key: "cour_degree_name", class: "text-left align-text-top text-nowrap w-200px", sortable: true },
          { label: "Specialization", key: "cour_name", class: "text-left align-text-top text-nowrap w-200px", sortable: true },
          { label: "Admission Cycle", key: "batch", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "Remarks", key: "adm_message", class: "text-left align-text-top text-nowrap w-200px", sortable: true },
          { label: "Applied On ", key: "created_on", class: "text-left align-text-top text-nowrap w-100px", sortable: true }
        ]
        break
      case "USERROLE11111": // What GIDE Admin Sees
      case "USERROLE11118":
        self.columns = [
          { label: "# ", key: "id", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "Action ", key: "action", class: "text-left align-text-top text-nowrap w-100px", sortable: false },
          { label: "Status", key: "adm_state", class: "text-left align-text-top text-nowrap w-125px", sortable: true },
          { label: "Name", key: "student_name", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "Email", key: "student_email", class: "text-left align-text-top text-nowrap w-260px", sortable: true },
          { label: "Contact No.", key: "student_mobile", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "School", key: "sch_name", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "City", key: "sch_city", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "Country", key: "sch_country", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "University", key: "org_name", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "Degree / Diploma", key: "cour_degree_name", class: "text-left align-text-top text-nowrap w-200px", sortable: true },
          { label: "Specialization", key: "cour_name", class: "text-left align-text-top text-nowrap w-200px", sortable: true },
          { label: "Admission Cycle", key: "batch", class: "text-left align-text-top text-nowrap w-100px", sortable: true },
          { label: "Remarks", key: "adm_message", class: "text-left align-text-top text-nowrap w-200px", sortable: true },
          { label: "Applied On ", key: "created_on", class: "text-left align-text-top text-nowrap w-100px", sortable: true }
        ]
        break
      default:
        break
    }

    this.admission_dashboard()
    this.analytics_admission_state()
  },
  methods: {
    getTitle (admState) {
      if (!this.userProfileUnlockState[admState]) {
        return "Application is Locked"
      }
      else {
        return "Application Open for Edit"
      }
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
     */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * getAdmissionStateColorCode
     */
    getAdmissionStateColorCode (statecode) {
      return `vertical-align: middle; color: #FFF; background: ${config.AdmissionStateInfoColors[statecode]} !important;`
    },
    /**
     * getStateDefinition
     */
    getStateDefinition (stateCode) {
      return config.AdmissionStateInfo[stateCode]
    },
    /**
     * Calling Function as per whereFilter
     */
    async getadmissionListDateWise () {
      this.analytics_admission_state()
      this.admission_dashboard()
      await this.setDateFilter()
    },
    /**
    *Admission Status
    */
    async analytics_admission_state () {
      try {
        let admListResp = await analyticsAdmissionDashboard.analytics_admission_state(this, this.whereFilter)
        if (admListResp.resp_status) {
          this.admDashBoardList = admListResp.resp_data.data
          this.totalRows = admListResp.resp_data.count
        }
        else {
          this.toastMsg = admListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at analytics_admission_state() and Exception:", err.message)
      }
    },
    /**
     * updateValues
     */
    updateValues (ev) {
      console.log(ev)
    },
    /**
     * admission_dashboard
     */
    async admission_dashboard () {
      try {
        let admissionListResp = await Admissions.admission_dashboard(this, this.whereFilter)
        if (admissionListResp.resp_status) {
          this.admissionObjList = admissionListResp.resp_data.data
          this.totalRows = admissionListResp.resp_data.count
        }
        else {
          this.toastMsg = admissionListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at admission_dashboard() and Exception:", err.message)
      }
    },
    /**
     * studentChangeApplicationStatus
     */
    async studentChangeApplicationStatus (selectedAdmissionItem, status) {
      const payload = {
        adm_id: selectedAdmissionItem.adm_id,
        adm_state: status
      }

      if (this.admissionObj.adm_reason_code) {
        payload.adm_message = `${this.admissionObj.adm_reason_code}: ${this.admissionObj.adm_message}`
        selectedAdmissionItem.adm_message = payload.adm_message
      }

      const admissionResp = await Admissions.admissionEdit(this, payload)
      if (admissionResp.resp_status) {
        selectedAdmissionItem.adm_state = status
        this.showRejectScreen = false
      }
    },
    /**
     * admissionEdit
     */
    async admissionEdit (item) {
      if (!this.userProfileUnlockState[item.adm_state]) {
        this.toastMsg = "Application is Currently Locked & in Review Stage"
        this.toastVariant = "danger"
        this.showToast = true
        return
      }

      try {
        this.$router.push(`/admission/${item.adm_id}/edit`)
      }
      catch (err) {
        console.error("Exception occurred at admissionEdit() and Exception:", err.message)
      }
    },
    /**
     * admissionView
     */
    admissionView (item) {
      this.$router.push(`/admission/${item.adm_id}/view`)
    },
    /**
     * showAdmissionDeleteDialog
     */
    showAdmissionDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelAdmissionDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showAdmissionDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * admissionDelete
     */
    async admissionDelete () {
      try {
        let admissionDelResp = await Admissions.admissionDelete(this, this.delObj.adm_id)
        await ApiResponse.responseMessageDisplay(this, admissionDelResp)
        if (admissionDelResp && !admissionDelResp) {
          this.showModelAdmissionDelete = false
          return false
        }
        let index = this.admissionObjList.indexOf(this.delObj)
        this.admissionObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelAdmissionDelete = false
      }
      catch (err) {
        console.error("Exception occurred at admissionDelete() and Exception:", err.message)
      }
    },
    /**
     * closeAdmissionAddModal
     */
    closeAdmissionAddModal (admissionAddData) {
      try {
        if (admissionAddData) {
          if (this.admissionObjList && this.admissionObjList.length >= 1) {
            let admObjLength = this.admissionObjList.length
            let lastId = this.admissionObjList[admObjLength - 1]["id"]
            admissionAddData.id = lastId + 1
          }
          else {
            this.admissionObjList = []
            admissionAddData.id = 11111
          }
          admissionAddData.created_on = moment()
          this.admissionObjList.unshift(admissionAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModelAdmissionAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeadmissionAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeAdmissionModal
     */
    closeAdmissionModal (event) {
      this.admissionOpenModal = false
      if (event && event.resp_status) {
        this.selectedAdmissionObj = {}
      }
    },
    /**
     * closeAdmissionEditModal
     */
    closeAdmissionEditModal () {
      try {
        this.showModelAdmissionEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeAdmissionEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
